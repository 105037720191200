import Box from "@mui/material/Box";
import React from "react";

import Navbar from "./Navbar";
import Description from "./Description";
import AboutUs from "./AboutUs";
import OurServices from "./OurServices";
import JobSeekers from "./JobSeekers";
import Footer from "./Footer";
const App = () => {
  return (
    <Box>
      <Navbar />
      <Description />
      <Box
        id="about-us-section"
        style={{ paddingTop: "112px", paddingBottom: "20px" }}
      >
        <AboutUs />
      </Box>

      <OurServices />
      <JobSeekers />
      <Footer />
    </Box>
  );
};
export default App;
