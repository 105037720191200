import Box from "@mui/material/Box";
import React, { useEffect } from "react";
import { AppBar, Toolbar, Button } from "@material-ui/core";
import fly from "./Image/FLYLOGO.png";
import { Link } from "react-scroll";
const Navbar = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleImageClick = () => {
    window.location.reload();
  };

  return (
    <Box id="home-section">
      <Box>
        <AppBar
          position="fixed"
          style={{
            backgroundColor: "#063b6f",
            height: "80px",
          }}
        >
          <Toolbar disableGutters>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "25%",
              }}
            >
              <img
                src={fly}
                alt="Logo"
                style={{
                  height: "180px",
                  width: "215px",
                  paddingBottom: "18px",
                }}
                onClick={handleImageClick}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "right",

                width: "75%",
              }}
            >
              <Button
                style={{
                  color: "white",
                  fontSize: "16px",
                  fontWeight: "bold",
                  marginRight: "35px",
                  marginLeft: "225px",
                  fontFamily: "calibre",
                }}
                component={Link}
                to="home-section"
                smooth={true}
                duration={500}
              >
                HOME
              </Button>
              <Button
                style={{
                  color: "white",
                  fontSize: "16px",
                  fontWeight: "bold",
                  marginRight: "35px",
                  fontFamily: "calibre",
                }}
                component={Link}
                to="about-us-section"
                smooth={true}
                duration={500}
              >
                ABOUT US
              </Button>
              <Button
                style={{
                  color: "white",
                  fontSize: "16px",
                  fontWeight: "bold",
                  marginRight: "35px",
                  fontFamily: "calibre",
                }}
                component={Link}
                to="our-services-section"
                smooth={true}
                duration={500}
              >
                OUR SERVICES
              </Button>
              <Button
                style={{
                  color: "white",

                  fontSize: "16px",
                  fontWeight: "bold",
                  marginRight: "35px",
                  fontFamily: "calibre",
                }}
                component={Link}
                to="job-seekers-section"
                smooth={true}
                duration={500}
              >
                JOB SEEKERS
              </Button>
              <Button
                style={{
                  color: "white",

                  fontSize: "16px",
                  fontWeight: "bold",
                  fontFamily: "calibre",
                }}
                component={Link}
                to="contact-section"
                smooth={true}
                duration={500}
              >
                CONTACT US
              </Button>
            </div>
          </Toolbar>
        </AppBar>
      </Box>
    </Box>
  );
};
export default Navbar;
