import React from "react";
import Box from "@mui/material/Box";
import ContentBack from "./Image/ContentBack.png";
import About from "./Image/aboutUs.jpg";
import Ourval from "./Image/OurValues.jpg";
import Team from "./Image/Team.jpg";
import PLRecord from "./Image/PLRecord.jpg";
import SendIcon from "@mui/icons-material/Send";
import SwipeableViews from "react-swipeable-views";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";

import { useState } from "react";

const AboutUs = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    event.preventDefault();
    setActiveTab(newValue);
  };

  const handleChangeIndex = (index) => {
    setActiveTab(index);
  };

  return (
    <Box
      style={{
        fontFamily: "calibre",
      }}
    >
      <TabContext value={activeTab.toString()}>
        <Tabs
          value={activeTab}
          onChange={handleChangeTab}
          indicatorColor="primary"
          style={{ marginLeft: "45px" }}
        >
          <Tab label="About Us" />
          <Tab label="Our Values" />
          <Tab label="Our Team" />
          <Tab label="Our Achievements" />
          <Tab label="Placement Record" />
        </Tabs>
        <SwipeableViews
          index={activeTab}
          onChangeIndex={handleChangeIndex}
          axis="x"
        >
          <TabPanel value={activeTab.toString()} index="0">
            <Box>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <h1
                  style={{
                    color: "#063b6f",
                    paddingLeft: "50px",
                    fontSize: "35px",
                    marginTop: "-4px",
                  }}
                >
                  ABOUT US
                </h1>
                <SendIcon
                  style={{
                    fontSize: "26px",
                    paddingLeft: "10px",
                    paddingTop: "2px",
                    color: "#063b6f",
                  }}
                />
              </div>
              <div
                style={{
                  height: "55vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    color: "black",
                    textAlign: "justify",
                    fontSize: "18px",
                    fontFamily: "calibre",
                    width: "50%",
                    lineHeight: "1.4",
                    overflow: "auto",
                    paddingRight: "20px",
                  }}
                >
                  <ul>
                    Fly Consulting Services is a leading recruitment firm that
                    specializes in helping clients find the right talent and job
                    seekers find their next career move. Our team of experienced
                    recruiters has a deep understanding of the industries we
                    serve and a proven track record of delivering customized
                    recruitment solutions to our clients.
                  </ul>
                  <ul>
                    At Fly Consulting Services, we believe in building long-term
                    relationships with our clients and candidates. We take the
                    time to understand our clients' needs, culture, and values,
                    and then develop customized recruitment solutions that meet
                    their unique needs. We also work closely with job seekers to
                    understand their career goals, skills, and experience, and
                    then connect them with opportunities that match their needs.
                  </ul>
                  <ul>
                    Our mission is to be the go-to recruitment partner for our
                    clients and candidates, providing them with the best
                    possible service and the highest-quality talent. We believe
                    in delivering value to our clients by providing top-tier
                    candidates, customized recruitment solutions, and unmatched
                    customer service.
                  </ul>
                </div>
                <div>
                  <img
                    src={About}
                    style={{
                      height: "320px",
                      width: "600px",
                      paddingTop: "30px",
                    }}
                  />
                </div>
              </div>
            </Box>
          </TabPanel>
          <TabPanel value={activeTab.toString()} index="1">
            <Box>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <h1
                  style={{
                    color: "#063b6f",
                    paddingLeft: "50px",
                    fontSize: "35px",
                    marginTop: "-4px",
                  }}
                >
                  OUR VALUES
                </h1>
                <SendIcon
                  style={{
                    fontSize: "26px",
                    paddingLeft: "10px",
                    paddingTop: "2px",
                    color: "#063b6f",
                  }}
                />
              </div>
              <div
                style={{
                  height: "55vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    color: "black",
                    fontFamily: "calibre",
                    width: "47%",
                    lineHeight: "1.3",

                    paddingRight: "20px",
                  }}
                >
                  <ul style={{ textAlign: "justify", fontSize: "18px" }}>
                    At Fly Consulting Services, we operate with the following
                    core values: <br />
                    <br />
                    <span style={{ fontWeight: "bold" }}>
                      Customer Focus:
                    </span>{" "}
                    We put our clients and candidates first, and we're committed
                    to providing them with the best possible service and
                    experience.
                    <br />
                    <br />
                    <span style={{ fontWeight: "bold" }}>Integrity:</span> We
                    believe in doing the right thing, even when it's not the
                    easiest thing to do. We operate with honesty, transparency
                    and ethics in everything we do.
                    <br />
                    <br />
                    <span style={{ fontWeight: "bold" }}>Teamwork:</span> We
                    work collaboratively to achieve our goals and deliver value
                    to our clients and candidates.
                    <br />
                    <br />
                    <span style={{ fontWeight: "bold" }}>
                      Continuous Improvement:
                    </span>{" "}
                    We're always learning, growing, and striving to be better.
                    We embrace change and innovation to stay ahead of the curve.
                  </ul>
                </div>
                <div>
                  <img
                    src={Ourval}
                    style={{
                      height: "320px",
                      width: "600px",
                      paddingTop: "30px",
                    }}
                  />
                </div>
              </div>
            </Box>
          </TabPanel>
          <TabPanel value={activeTab.toString()} index="2">
            <Box>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <h1
                  style={{
                    color: "#063b6f",
                    paddingLeft: "50px",
                    fontSize: "35px",
                    marginTop: "-4px",
                  }}
                >
                  OUR TEAM
                </h1>
                <SendIcon
                  style={{
                    fontSize: "26px",
                    paddingLeft: "10px",
                    paddingTop: "4px",
                    color: "#063b6f",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    color: "black",
                    textAlign: "justify",
                    fontSize: "18px",
                    fontFamily: "calibre",
                    width: "48%",
                    paddingInLeft: "50px",
                    lineHeight: "1.5",
                  }}
                >
                  <ul>
                    Our team of experienced recruiters is passionate about
                    helping our clients find the right talent and our candidates
                    find the right job. Our recruiters have deep industry
                    knowledge, and they're committed to providing customized
                    solutions that meet our clients' unique needs.
                  </ul>
                  <br />
                  <ul>
                    We believe that diversity and inclusion are critical to the
                    success of our business, and we're committed to building a
                    team that reflects the communities we serve. Our team is
                    diverse in terms of gender, ethnicity, and background, and
                    we value the unique perspectives and experiences that each
                    team member brings to the table.
                  </ul>
                </div>
                <div>
                  <img
                    src={Team}
                    style={{
                      height: "320px",
                      width: "600px",
                      paddingTop: "20px",
                    }}
                  />
                </div>
              </div>
            </Box>
          </TabPanel>
          <TabPanel value={activeTab.toString()} index="3">
            <Box style={{}}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <h1
                  style={{
                    color: "#063b6f",
                    paddingLeft: "50px",
                    fontSize: "35px",
                    marginTop: "-4px",
                  }}
                >
                  OUR ACHIEVEMENTS
                </h1>
                <SendIcon
                  style={{
                    fontSize: "26px",
                    paddingLeft: "10px",
                    paddingTop: "4px",
                    color: "#063b6f",
                  }}
                />
              </div>
              <div
                style={{
                  height: "55vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    color: "black",
                    textAlign: "justify",
                    fontSize: "18px",
                    fontFamily: "calibre",
                    width: "48%",
                    paddingInLeft: "50px",
                    lineHeight: "1.5",
                    overflow: "auto",
                    paddingRight: "20px",
                  }}
                >
                  <ul>
                    At Fly Consulting Services, we pride ourselves on our
                    ability to deliver high-quality recruitment solutions to our
                    clients. Our team of experienced recruiters has a proven
                    track record of success, and we've achieved several notable
                    accomplishments over the years.
                    <br />
                    <br />
                    Here are some of our achievements:
                  </ul>

                  <ul>
                    <span style={{ fontWeight: "bold" }}>
                      Placed thousands of candidates:
                    </span>{" "}
                    Over the years, we've helped thousands of candidates find
                    their dream job. We're proud to have played a role in their
                    success and to have helped them achieve their career goals.
                    <br />
                    <br />
                    <span style={{ fontWeight: "bold" }}>
                      Built long-term relationships with clients:
                    </span>{" "}
                    We believe in building long-term relationships with our
                    clients, and we're proud to say that many of our clients
                    have been with us for years. We work closely with our
                    clients to understand their needs, and we're committed to
                    delivering customized solutions that meet those needs.
                    <br />
                    <br />
                    <span style={{ fontWeight: "bold" }}>
                      Worked with top-tier companies:
                    </span>{" "}
                    We've had the opportunity to work with some of the top
                    companies in the industries we serve. Our clients range from
                    Fortune 500 companies to small startups, and we're proud to
                    have helped them all find the right talent.
                    <br />
                    <br />
                    <span style={{ fontWeight: "bold" }}>
                      Developed a diverse team:
                    </span>{" "}
                    We're committed to diversity and inclusion, and we've worked
                    hard to build a team that reflects the communities we serve.
                    Our team is diverse in terms of gender, ethnicity, and
                    background, and we value the unique perspectives and
                    experiences that each team member brings to the table.
                    <br />
                    <br />
                    <span style={{ fontWeight: "bold" }}>
                      Received positive feedback from clients and candidates:
                    </span>{" "}
                    We're proud to have received positive feedback from both our
                    clients and candidates. We believe in providing exceptional
                    customer service, and we're thrilled to hear that our
                    clients and candidates appreciate our efforts.
                  </ul>
                </div>
                <div>
                  <img
                    src={ContentBack}
                    style={{
                      height: "300px",
                      width: "600px",
                      paddingTop: "30px",
                    }}
                  />
                </div>
              </div>
            </Box>
          </TabPanel>
          <TabPanel value={activeTab.toString()} index="4">
            <Box>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <h1
                  style={{
                    color: "#063b6f",
                    paddingLeft: "50px",
                    fontSize: "35px",
                    marginTop: "-4px",
                  }}
                >
                  OUR PLACEMENT RECORD
                </h1>
                <SendIcon
                  style={{
                    fontSize: "26px",
                    paddingLeft: "10px",
                    paddingTop: "4px",
                    color: "#063b6f",
                  }}
                />
              </div>
              <div
                style={{
                  height: "55vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    color: "black",
                    textAlign: "justify",
                    fontSize: "18px",
                    fontFamily: "calibre",
                    width: "48%",
                    paddingInLeft: "50px",
                    lineHeight: "1.5",
                    overflow: "auto",
                    paddingRight: "20px",
                  }}
                >
                  <ul>
                    At Fly Consulting Services, we're proud of our track record
                    of successfully placing candidates in a wide range of
                    industries and positions. Our team of experienced recruiters
                    works tirelessly to find the right fit for our clients and
                    candidates, and we've achieved a high rate of success over
                    the years.
                    <br />
                    <br />
                    Here are some of the key highlights of our placement record:
                  </ul>
                  <ul>
                    <span style={{ fontWeight: "bold" }}>
                      Placed candidates in a variety of industries:
                    </span>{" "}
                    Our team has successfully placed candidates in a wide range
                    of industries, including finance, healthcare, technology,
                    manufacturing, and more. We understand the unique needs of
                    each industry, and we work closely with our clients to find
                    candidates who have the right skills and experience.
                    <br />
                    <br />
                    <span style={{ fontWeight: "bold" }}>
                      Placed candidates in a variety of positions:
                    </span>{" "}
                    We've placed candidates in a variety of positions, from
                    entry-level roles to executive positions. Our team has the
                    expertise and knowledge to find the right candidate for any
                    position.
                    <br />
                    <br />
                    <span style={{ fontWeight: "bold" }}>
                      High placement success rate:
                    </span>{" "}
                    We're proud to have a high placement success rate, which is
                    a testament to our team's dedication and expertise. We work
                    closely with our clients to understand their needs and find
                    candidates who are the right fit for their organization.
                    <br />
                    <br />
                    <span style={{ fontWeight: "bold" }}>
                      {" "}
                      Long-term placements:
                    </span>{" "}
                    We believe in building long-term relationships with our
                    clients and candidates, and many of our placements have
                    turned into long-term employment opportunities. We're proud
                    to have played a role in our candidates' career growth and
                    our clients' success.
                    <br />
                    <br />
                    <span style={{ fontWeight: "bold" }}>
                      Positive feedback from clients and candidates:
                    </span>{" "}
                    We're committed to providing exceptional customer service,
                    and we're thrilled to have received positive feedback from
                    both our clients and candidates. We believe in transparency
                    and communication throughout the placement process, and
                    we're always available to answer any questions or concerns.
                  </ul>
                </div>
                <div>
                  <img
                    src={PLRecord}
                    style={{
                      height: "300px",
                      width: "600px",
                      paddingTop: "30px",
                    }}
                  />
                </div>
              </div>
            </Box>
          </TabPanel>
        </SwipeableViews>
      </TabContext>
    </Box>
  );
};
export default AboutUs;
