import React from "react";
import SendIcon from "@mui/icons-material/Send";
import { useState } from "react";
import Box from "@mui/material/Box";
import { IconButton, Collapse, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StarIcon from "@mui/icons-material/Star";
import OurSer from "./Image/ourSer.png";

const OurServices = () => {
  const [open, setOpen] = useState([false, false, false, false, false]);

  const handleClick = (index) => {
    const updatedOpen = [...open];
    updatedOpen[index] = !updatedOpen[index];
    setOpen(updatedOpen);
  };

  return (
    <Box
      id="our-services-section"
      style={{
        paddingBottom: "100px",
        fontFamily: "calibre",
        overflow: "auto",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <h1
          style={{
            color: "#063b6f",
            paddingLeft: "50px",
            fontSize: "35px",
            paddingTop: "90px",
          }}
        >
          OUR SERVICES
        </h1>
        <SendIcon
          style={{
            fontSize: "26px",
            paddingLeft: "10px",
            paddingTop: "120px",
            color: "#063b6f",
          }}
        />
      </div>

      <div
        style={{
          height: "68vh",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: "45" }}>
          <img
            src={OurSer}
            style={{
              height: "350px",
              width: "600px",
              paddingTop: "60px",
              paddingLeft: "20px",
            }}
          />
        </div>
        <div style={{ overflow: "auto", width: "55%" }}>
          <Box>
            <Box>
              <div
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontSize: "18px",
                  fontFamily: "calibre",
                  paddingRight: "20px",
                  paddingInLeft: "50px",
                  lineHeight: "1.3",
                }}
              >
                <ul>
                  At Fly Consulting Services, we offer a range of recruitment
                  services to meet the needs of our clients. Our team of
                  experienced recruiters specializes in a wide range of
                  industries, from finance and accounting to healthcare and
                  technology. We work closely with our clients to understand
                  their unique needs and then develop customized solutions to
                  meet those needs.
                </ul>
                <ul>
                  At Fly Consulting Services, we offer a range of recruitment
                  services to meet the needs of our clients, including:
                </ul>
              </div>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  paddingLeft: "30px",
                  color: "black",
                }}
              >
                <Box>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <StarIcon sx={{ color: "#FFA500", paddingRight: "20px" }} />
                    <Typography
                      variant="subtitle1"
                      style={{
                        fontFamily: "Teko, sans-serif",
                        fontSize: "26px",
                        color: "#FFA500",
                      }}
                    >
                      EXECUTIVE SEARCH
                    </Typography>
                    <IconButton
                      onClick={() => handleClick(0)}
                      aria-expanded={open[0]}
                      aria-label="Expand"
                    >
                      <ExpandMoreIcon style={{ color: "black" }} />
                    </IconButton>
                  </div>
                  <Collapse in={open[0]} sx={{ width: "90%" }}>
                    <Typography
                      style={{
                        color: "black",
                        fontFamily: "Teko, sans-serif",
                        fontSize: "20px",
                        textAlign: "justify",
                      }}
                    >
                      Our executive search service is designed for clients who
                      are looking for high-level talent to lead their
                      organization. We work closely with our clients to
                      understand their needs and then conduct an extensive
                      search to identify the best candidates for the job. Our
                      executive search service is a comprehensive solution that
                      includes sourcing, screening, interviewing, and presenting
                      candidates to our clients.
                    </Typography>
                  </Collapse>
                </Box>
                <Box>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <StarIcon sx={{ color: "#FFA500", paddingRight: "20px" }} />
                    <Typography
                      variant="subtitle1"
                      style={{
                        fontFamily: "Teko, sans-serif",
                        fontSize: "26px",
                        color: "#FFA500",
                      }}
                    >
                      DIRECT HIRE PLACEMENT
                    </Typography>
                    <IconButton
                      onClick={() => handleClick(1)}
                      aria-expanded={open[1]}
                      aria-label="Expand"
                    >
                      <ExpandMoreIcon style={{ color: "black" }} />
                    </IconButton>
                  </div>
                  <Collapse in={open[1]} sx={{ width: "90%" }}>
                    <Typography
                      style={{
                        fontFamily: "Teko, sans-serif",
                        fontSize: "20px",
                        color: "black",
                        textAlign: "justify",
                      }}
                    >
                      Our direct hire placement service is designed for clients
                      who are looking to hire full-time employees for their
                      organization. We work closely with our clients to
                      understand their needs and then source, screen, and
                      present candidates who are the right fit for the job. Our
                      direct hire placement service is a cost-effective solution
                      that helps our clients save time and money while finding
                      the best talent.
                    </Typography>
                  </Collapse>
                </Box>

                <Box>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <StarIcon sx={{ color: "#FFA500", paddingRight: "20px" }} />
                    <Typography
                      variant="subtitle1"
                      style={{
                        fontFamily: "Teko, sans-serif",
                        fontSize: "26px",
                        color: "#FFA500",
                      }}
                    >
                      TEMPORARY AND CONTRACT STAFFING
                    </Typography>
                    <IconButton
                      onClick={() => handleClick(2)}
                      aria-expanded={open[2]}
                      aria-label="Expand"
                    >
                      <ExpandMoreIcon style={{ color: "black" }} />
                    </IconButton>
                  </div>
                  <Collapse in={open[2]} sx={{ width: "90%" }}>
                    <Typography
                      style={{
                        fontFamily: "Teko, sans-serif",
                        fontSize: "20px",
                        color: "black",
                        textAlign: "justify",
                      }}
                    >
                      Our temporary and contract staffing service is designed
                      for clients who are looking for short-term staffing
                      solutions. We work closely with our clients to understand
                      their needs and then provide them with highly-skilled and
                      qualified professionals who can fill their temporary
                      staffing needs. Our temporary and contract staffing
                      service is flexible and cost-effective, making it a great
                      option for clients who need staffing solutions on an
                      as-needed basis.
                    </Typography>
                  </Collapse>
                </Box>
                <Box>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <StarIcon sx={{ color: "#FFA500", paddingRight: "20px" }} />
                    <Typography
                      variant="subtitle1"
                      style={{
                        fontFamily: "Teko, sans-serif",
                        fontSize: "26px",
                        color: "#FFA500",
                      }}
                    >
                      RECRUITMENT PROCESS OUTSOURCING (RPO)
                    </Typography>
                    <IconButton
                      onClick={() => handleClick(3)}
                      aria-expanded={open[3]}
                      aria-label="Expand"
                    >
                      <ExpandMoreIcon style={{ color: "black" }} />
                    </IconButton>
                  </div>
                  <Collapse in={open[3]} sx={{ width: "90%" }}>
                    <Typography
                      style={{
                        fontFamily: "Teko, sans-serif",
                        fontSize: "20px",
                        color: "black",
                        textAlign: "justify",
                      }}
                    >
                      Our recruitment process outsourcing service is designed
                      for clients who are looking to outsource some or all of
                      their recruitment needs. We work closely with our clients
                      to understand their needs and then provide them with a
                      customized solution that includes sourcing, screening, and
                      presenting candidates. Our recruitment process outsourcing
                      service is a comprehensive solution that helps our clients
                      save time and money while finding the best talent.
                    </Typography>
                  </Collapse>
                </Box>
                <Box>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <StarIcon sx={{ color: "#FFA500", paddingRight: "20px" }} />
                    <Typography
                      variant="subtitle1"
                      style={{
                        fontFamily: "Teko, sans-serif",
                        fontSize: "26px",
                        color: "#FFA500",
                      }}
                    >
                      EMPLOYER BRANDING AND MARKETING
                    </Typography>
                    <IconButton
                      onClick={() => handleClick(4)}
                      aria-expanded={open[4]}
                      aria-label="Expand"
                    >
                      <ExpandMoreIcon style={{ color: "black" }} />
                    </IconButton>
                  </div>
                  <Collapse in={open[4]} sx={{ width: "90%" }}>
                    <Typography
                      style={{
                        fontFamily: "Teko, sans-serif",
                        fontSize: "20px",
                        color: "black",
                        textAlign: "justify",
                      }}
                    >
                      Our employer branding and marketing service is designed
                      for clients who are looking to improve their employer
                      brand and attract the best talent. We work closely with
                      our clients to understand their needs and then develop a
                      customized solution that includes employer branding and
                      marketing strategies. Our employer branding and marketing
                      service is a comprehensive solution that helps our clients
                      stand out in a crowded job market.
                    </Typography>
                  </Collapse>
                </Box>
              </Box>
              <ul
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontSize: "18px",
                  fontFamily: "calibre",
                  paddingInLeft: "50px",
                  lineHeight: "1.5",
                  paddingRight: "20px",
                }}
              >
                Our expertise and industry knowledge enable us to provide our
                clients with top-tier candidates, customized solutions, and
                unmatched customer service.
              </ul>
            </Box>
          </Box>
        </div>
      </div>
    </Box>
  );
};
export default OurServices;
