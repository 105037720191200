import React from "react";
import Box from "@mui/material/Box";
import JobSee from "./Image/JobSee.png";
import SendIcon from "@mui/icons-material/Send";
const JobSeekers = () => {
  return (
    <Box
      sx={{
        fontFamily: "calibre",
        paddingTop: "110px",
        paddingBottom: "50px",
      }}
      id="job-seekers-section"
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <h1
          style={{
            color: "#063b6f",
            paddingLeft: "50px",
            fontSize: "35px",
          }}
        >
          JOB SEEKERS
        </h1>
        <SendIcon
          style={{
            fontSize: "26px",
            paddingLeft: "10px",
            paddingTop: "29px",
            color: "#063b6f",
          }}
        />
      </div>
      <div
        style={{
          height: "65vh",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            color: "black",
            textAlign: "justify",
            fontSize: "18px",
            fontFamily: "calibre",
            width: "48%",
            paddingInLeft: "50px",
            lineHeight: "1.4",
            overflow: "auto",
            paddingRight: "20px",
          }}
        >
          <ul>
            At Fly Consulting Services, we understand that finding the right
            talent can be challenging, time-consuming, and expensive. That's why
            we offer a range of recruitment services to help our clients find
            the right talent, quickly and efficiently.
          </ul>
          <ul>
            Our team of experienced recruiters specializes in a wide range of
            industries, from finance and accounting to healthcare and
            technology. We work closely with our clients to understand their
            unique needs, and then develop customized solutions to meet their
            recruitment needs.
          </ul>
          <ul>
            What sets us apart is our commitment to quality and customer
            service. We take the time to understand our clients' needs and
            ensure that we provide the best possible candidate for each role. We
            pride ourselves on building long-term relationships with our
            clients, based on trust, transparency, and reliability.
          </ul>
          <ul>
            If you're looking for your next career move, Fly Consulting Services
            is here to help. We work with a wide range of employers across
            various industries, and we can connect you with exciting
            opportunities that match your skills and experience.
          </ul>
          <ul>
            We believe in building long-term relationships with our candidates,
            based on trust, transparency, and respect. Our team of experienced
            recruiters will work with you to understand your career goals,
            skills, and experience, and then help you find the right job that
            matches your needs.
          </ul>
          <ul>
            What sets us apart is our commitment to quality and customer
            service. We take the time to understand our clients' needs and
            ensure that we provide the best possible candidate for each role. We
            pride ourselves on building long-term relationships with our
            clients, based on trust, transparency, and reliability.
          </ul>
        </div>
        <div>
          <img
            src={JobSee}
            style={{
              height: "360px",
              width: "600px",
              paddingTop: "30px",
              paddingRight: "25px",
            }}
          />
        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <h1
          style={{
            color: "#063b6f",
            paddingLeft: "50px",
            fontSize: "35px",
            paddingTop: "120px",
          }}
        >
          GET IN TOUCH
        </h1>
        <SendIcon
          style={{
            fontSize: "26px",
            paddingLeft: "10px",
            paddingTop: "150px",
            color: "#063b6f",
          }}
        />
      </div>
      <div
        style={{
          color: "black",
          textAlign: "justify",
          fontSize: "19px",
          fontFamily: "calibre",
          width: "90%",
          paddingInLeft: "50px",
          lineHeight: "2.0",
        }}
      >
        <ul>
          If you're looking for a trusted recruitment partner that can help you
          find the right talent or the right job, contact Fly Consulting
          Services today. Our team is always here to help, and we look forward
          to hearing from you soon.
        </ul>
      </div>
    </Box>
  );
};
export default JobSeekers;
