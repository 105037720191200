import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import SocialMedia from "./SocialMedia";
import { Link } from "@mui/material";
import PlaceIcon from "@mui/icons-material/Place";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: "#37474f",
    padding: theme.spacing(5),
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    borderBottomLeftRadius: "20px",
    borderBottomRightRadius: "20px",
  },
  boxContainer: {
    display: "flex",
    flexDirection: "row",
  },
}));

function Footer() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Box id="contact-section" className={classes.boxContainer}>
        <Box
          style={{
            width: "33%",
            backgroundColor: "primary.main",
            fontFamily: "Teko, sans-serif",
            color: "white",
            fontSize: "20px",
          }}
        >
          <h1 style={{ color: "#8bc34a" }}>FLY CONSULTING SERVICES</h1>
          <p>
            3rd floor, NH163, Venkateswara Colony, Vijayapuri Colony,
            <br /> Uppal Opposite to FB Cakes, Touch Mobile Building, Uppal,
            <br />
            Telangana - 500039
          </p>
        </Box>
        <Box
          style={{
            width: "20%",
            backgroundColor: "primary.main",
            fontFamily: "Teko, sans-serif",
            color: "white",
            fontSize: "20px",
            textAlign: "center",
          }}
        >
          <h3 style={{ color: "#00bcd4", fontSize: "28px" }}>CONTACT US</h3>
          <p>
            Office:&nbsp;040-47087381
            <br />
            Phone:&nbsp;9176985050
            <br />
            Email:&nbsp;Hr@flyconsultingservices.com
          </p>
        </Box>

        <Box
          style={{
            width: "18%",
            backgroundColor: "primary.main",
            fontFamily: "Teko, sans-serif",
            color: "white",
            fontSize: "19px",
            textAlign: "center",
          }}
        >
          <h3 style={{ color: "#00bcd4", fontSize: "28px" }}>GET DIRECTIONS</h3>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <PlaceIcon />
            <Link
              style={{ color: "white" }}
              href="https://www.google.com/maps/dir/15.5337435,73.7595528/Fly+Consulting+Services/@16.6878708,73.9707689,7z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3bcb991e36123227:0x8041706c7c8af90a!2m2!1d78.5623978!2d17.401324?hl=en-US&entry=ttu"
              target="_blank"
              rel="noopener"
            >
              Google Maps
            </Link>
          </div>
        </Box>
        <Box
          style={{
            width: "26%",
            backgroundColor: "primary.main",
            fontFamily: "Teko, sans-serif",
            color: "white",
            fontSize: "19px",
            textAlign: "center",
          }}
        >
          {" "}
          <div>
            <h3 style={{ color: "#00bcd4", fontSize: "28px" }}>FOLLOW US ON</h3>
            <SocialMedia />
          </div>
        </Box>
      </Box>
    </footer>
  );
}

export default Footer;
