import React from "react";
import { IconButton } from "@material-ui/core";
import {
  Facebook as FacebookIcon,
  Twitter as TwitterIcon,
  LinkedIn as LinkedInIcon,
  Instagram as InstagramIcon,
} from "@material-ui/icons";

export default function SocialFollow() {
  return (
    <div>
      <IconButton
        color="blue"
        href="https://www.facebook.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FacebookIcon style={{ fontSize: "26px", color: "white" }} />
      </IconButton>
      <IconButton
        color="blue"
        href="https://twitter.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <TwitterIcon style={{ fontSize: "26px", color: "white" }} />
      </IconButton>
      <IconButton
        color="blue"
        href="https://www.linkedin.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <LinkedInIcon style={{ fontSize: "26px", color: "white" }} />
      </IconButton>
      <IconButton
        color="red"
        href="https://www.instagram.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <InstagramIcon style={{ fontSize: "26px", color: "white" }} />
      </IconButton>
    </div>
  );
}
