import Video from "./Video/NBackVid.mp4";
import "./BackgroundVideo.css";
import React, { useEffect, useState } from "react";

const Description = () => {
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowText(true);
      clearTimeout(timer);
    }, 2000); // Adjust the delay as needed

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div className="video-background">
        <video autoPlay muted loop className="dimmed-video">
          <source src={Video} type="video/mp4" />
        </video>
        <div className="content">
          {showText && (
            <h1
              className="animated-text"
              style={{
                color: "#21384e",
                paddingTop: "410px",
                marginLeft: "80px",
                fontSize: "35px",
                fontFamily: "Roboto Slab, serif",
              }}
            >
              <span className="folding-box">
                <span className="folded-text">
                  Welcome to{" "}
                  <span
                    style={{
                      color: "black",
                      fontFamily: "Roboto Slab, serif",
                    }}
                  >
                    FLY CONSULTING SERVICES
                  </span>
                  <br />
                  Your Trusted Recruitment Partner
                </span>
              </span>
            </h1>
          )}
        </div>
      </div>
    </>
  );
};
export default Description;
